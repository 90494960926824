import React from "react"
import Layout from "../../components/layout"
import Screenshot from "../../img/client-wso2-screens@2x.jpg"

export default ({ location, data }) => {
  return (
    <Layout location={location}>
      <div className="cover-heading-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-10">
              <h1 className="cover-heading">WSO2</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="cover-wrapper cover-wso2">
        <div className="cover-wrapper-inner">
          <div className="cover-container">
            <div className="inner cover"></div>
          </div>
        </div>
      </div>
      <section className="m-t-100 sm-m-t-20">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="">
                <h4>Client</h4>
                <p className="m-b-20">WSO2 Lanka Pvt Ltd, Sri Lanka.</p>

                <h4>Roles</h4>
                <p className="m-b-20">
                  UI/UX Design, Mobile App Development (iOS, Android) &amp; Backend
                  Development
                </p>

                <p className="m-b-20">
                  <a rel="noopener" href="http://eu17.wso2con.com" target="_blank">
                    eu17.wso2con.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <p>
                WSO2 is an open source technology provider that increases the
                agility of digital businesses and enterprises engaging in
                digital transformation. With offices in USA, UK and Sri Lanka,
                WSO2 has been able to serve many customers worldwide including
                Fortune 500 companies such as eBay, Trimble, West Interactive
                &amp; StubHub.
              </p>
              <p>
                They came to us to create the iOS and Android Mobile
                applications and the Backend Service for the WSO2Con: an annual
                conference hosted by WSO2, held in the USA, Europe and Asia. We
                focused our attention to provide a User Experience that allowed
                conference attendees to easily keep up with the conference
                events.
              </p>
            </div>
          </div>

          <div className="shot m-t-50 sm-m-t-20 m-b-50 sm-m-b-20">
            <img alt="WSO2 app screens" src={Screenshot} />
          </div>
        </div>
      </section>
    </Layout>
  )
}
